<template>
  <div class="active pl40 pb30 pt40">
    <my-header type="back" style='margin-right: 40px'></my-header>
    <div class="bigTitle f18 text-left pt30">{{info.name}}</div>
    <div class="hard flex row-between plr30 mt25 mr20">
      <div class="gotime flex">
        <div class="btime f12 pr55"><img src="../../assets/img/img16.png" >报名时间：{{info.bmStartDate}} - {{info.bmEndDate}}</div>
        <div class="btime f12"><img src="../../assets/img/img17.png" >活动时间：{{info.startDate}} - {{info.endDate}}</div>
      </div>
      <div class="sobtne flex f12">
        <div class="itemBtn color1">{{info.joinQty}}人已报名</div>
        <div class="itemBtn cup" v-if="info.joinQty >= info.maxQty">已报满</div>
        <div class="itemBtn cup" v-else :class="info.isJoin?'color2':'color1'" @click="join">{{info.isJoin?'取消报名':'一键报名'}}</div>
      </div>
    </div>
    <div class="content text-left mt25" v-html="info.description"></div>
    <modal ref="modal" type='alert' @click="cancelJoin"></modal>
  </div>
</template>

<script>
  import myHeader from '../../components/head.vue';
  import modal from '../../components/modal.vue'
  import { getItem } from '../../assets/js/storage.js'
  export default{
    components: {
      myHeader,
      modal
    },
    data(){
      return{
        id: '',
        user: '',
        info: ''
      }
    },
    mounted() {
      this.id = this.$route.query.id;
      var user = getItem('user');
      if(user) this.user = user;
      this.getData();
    },
    methods:{
      async getData(){
        var params = {
          id: this.id
        }
        var res = await this.$api.get('/api/project_camp/info', params);
        if(res.code == 0){
          var data = res.data.info;
          data.bmStartDate = data.bmStartDate.replace(/-/g, '.');
          data.bmEndDate = data.bmEndDate.replace(/-/g, '.');
          data.startDate = data.startDate.replace(/-/g, '.');
          data.endDate = data.endDate.replace(/-/g, '.');
          this.info = data;
        }
      },
      // 一键报名
      async join(){
        if(!this.user) return this.$bus.$emit('isLogin');
        if(this.info.isJoin) return this.$refs.modal.showHand();
        
        if(!this.info.isJoin){
          var startTime = new Date(new Date(this.info.bmStartDate).toLocaleDateString()).getTime().toString().substring(0,10);
          var endTime = (new Date(new Date(this.info.bmEndDate).toLocaleDateString()).getTime()+24 * 60 * 60 * 1000 -1).toString().substring(0,10);
          var nowTime = new Date().getTime().toString().substring(0,10);
          if(Number(startTime) > Number(nowTime)) return this.$message.warning('暂未到报名时间');
          if(Number(endTime) < Number(nowTime)) return this.$message.warning('报名时间已结束');
        }
        
        var res = await this.$api.post('/api/project_camp/join', {id: this.info.id});
        if(res.code == 0){
          await this.getData();
          this.$message.success('报名成功');
          //初始化当前用户报名了多少个活动
          this.$store.dispatch('setNumModal');
        }
      },
      // 取消报名
      async cancelJoin(){
        var res = await this.$api.post('/api/project_camp/cancelJoin', {id: this.id});
        if(res.code == 0){
          await this.getData();
          this.$refs.modal.hideHand();
          this.$message.success('取消成功');
          //初始化当前用户报名了多少个活动
          this.$store.dispatch('setNumModal');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .active{
    height: 100%;
    .content{
      overflow: auto;
      max-height: calc(100% - 200px);
      ::v-deep img{
        width: 100%;
      }
    }
    .hard{
      background-color: #f7f7f7;
      height: 60px;
      .sobtne{
        height: 33px;
        .itemBtn{
          width: 97px;
          height: 33px;
          line-height: 33px;
          border-radius: 33px;
          border: 1px solid;
          &:first-child{
            margin-right: 20px;
          }
          &.color2{
            color: #999999;
            border-color: #999999;
          }
          &.color1{
            color: #272a2d;
            border-color: #6672fb;
          }
        }
      }
      .gotime{
        .btime{
          color: #000000;
          img{
            vertical-align: middle;
            margin-right: 10px;
            width: 16px;
            height: 17px;
          }
        }
      }
    }
    .bigTitle{
      color: #272a2d;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-weight: bold;
    }
    .back{
      color: #6672fb;
      span{
        font-weight: bold;
      }
      img{
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 15px;
      }
    }
  }
</style>
