<template>
  <div style="position: fixed; z-index: 9999;" v-if="show">
    <div class="mask"></div>
    <div class="modal" v-if="type == 'alert'">
      <div class="prst">
        <div class="tte f28 pt60">取消报名</div>
        <div class="delete cup" @click="hideHand()"><img src="../assets/img/img18.png" ></div>
        <div class="ok f25 cup" @click="cancelJoin">OK</div>
        <div class="monkey"><img src="../assets/img/monkey.png" ></div>
      </div>
    </div>
    <div class="modal mesWidth" v-else>
      <div class="prst">
        <div class="tcPa">
          <div class="tc f24 pt40 pb10">暂未获得权限</div>
          <div class="tc f18">Unauthorized</div>
        </div>
        <div class="delete cup" @click="hideHand()"><img src="../assets/img/img18.png" ></div>
        <div class="meng"><img src="../assets/img/img24.png" ></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props:{
      type: {
        type: String,
        default:''
      }
    },
    data(){
      return{
        show: false
      }
    },
    methods:{
      // 取消报名
      cancelJoin(){
        this.$emit('click')
      },
      showHand(){
        this.show = true;
      },
      hideHand(){
        this.show = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modal{
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    width: 253px;
    height: 254px;
    background-color: #FFFFFF;
    border-radius: 20px;
    &.mesWidth{
      width: 318px;
    }
    .prst{
      position: relative;
      width: 100%;
      height: 100%;
      .tcPa{
        position: absolute;
        z-index: 3;
        left: 118px;
        top: 50px;
        text-align: left;
      }
      .meng{
        position: absolute;
        left: -125px;
        top: 11px;
        z-index: 2;
      }
      .monkey{
        position: absolute;
        left: -112px;
        top: 0px;
        z-index: 2;
      }
      .ok{
        font-weight: bold;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
        z-index: 3;
        width: 148px;
        height: 45px;
        border-radius: 45px;
        line-height: 45px;
        background-color: #5d69fb;
        color: #FFFFFF;
      }
      .delete{
        position: absolute;
        right: 12px;
        top: 12px;
      }
      .tc{
        color: #5d69fb;
      }
      .tte{
        color: #5d69fb;
        font-weight: bold;
      }
    }
  }
  .mask{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
  }
</style>
